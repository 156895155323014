import * as bootstrap from 'bootstrap'


function toggleSidebar() {
    const body = document.querySelector('body');
    body.classList.toggle('collapse-sidebar');
}

window.mightyjs = {
    toggleSidebar
}
